<template>
  <!-- 新增仓库 -->
  <div class="store-add">
    <el-dialog
      :visible="OpenStoreAdd"
      title="新增仓库"
      v-dialogDrag="true"
      width="800px"
      height="600px"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <!-- 基本信息 -->
        <div class="inforTitle">基本信息</div>
        <!-- 基本信息第一层 -->
        <el-row>
          <el-col :span="12">
            <el-form-item label="仓库编码" prop="storeNo">
              <el-input v-model="form.storeNo" placeholder="请输入仓库编码" maxlength="11" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仓库名称" prop="storeName">
              <el-input v-model="form.storeName" placeholder="请输入仓库名称" maxlength="11" />
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 基本信息第二层 -->
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属机构" prop="deptId">
              <treeselect
                v-model="form.deptId"
                :options="depOptions"
                :show-count="true"
                placeholder="请选择所属机构"
                :normalizer="normalizer"
                @blur="$refs.form.validateField('deptId')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="仓库类型" prop="storeType">
              <el-select class="select" v-model="form.storeType" placeholder="请选择仓库类型" clearable>
                <el-option
                  v-for="dict in dict.type.store_type"
                  :key="dict.value"
                  :label="dict.label"
                  :value="parseInt(dict.value)"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 基本信息第三层 -->
        <el-row>
          <el-col :span="20">
            <el-form-item label="仓库状态">
              <el-radio-group v-model="form.status">
                <el-radio
                  v-for="dict in dict.type.store_status"
                  :key="dict.value"
                  :label="parseInt(dict.value)"
                >{{ dict.label }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="允许采购" prop="isAllowPurchase">
          <el-radio-group v-model="form.isAllowPurchase">
            <el-radio
              v-for="dict in dict.type.App_is_allow_purchase"
              :key="dict.value"
              :label="parseInt(dict.value)"
            >{{ dict.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="允许销售" prop="isAllowSale">
          <el-radio-group v-model="form.isAllowSale">
            <el-radio
              v-for="dict in dict.type.App_is_allow_sale"
              :key="dict.value"
              :label="parseInt(dict.value)"
            >{{ dict.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="允许负库存" prop="isAllowMinus">
          <el-radio-group v-model="form.isAllowMinus">
            <el-radio
              v-for="dict in dict.type.App_is_allow_minus"
              :key="dict.value"
              :label="parseInt(dict.value)"
            >{{ dict.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="计价方式" prop="costMode">
          <el-radio-group v-model="form.costMode">
            <el-radio
              v-for="dict in dict.type.cost_mode"
              :key="dict.value"
              :label="parseInt(dict.value)"
            >{{ dict.label }}</el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- 联系信息 -->
        <div class="inforTitle">联系信息</div>
        <!-- 联系信息第一层 -->
        <el-row>
          <el-col :span="12">
            <el-form-item label="仓库负责人" prop="storeEmployeeId">
              <treeselect
                v-model="form.storeEmployeeId"
                :options="contactsList"
                :show-count="true"
                placeholder="请选择仓库负责人"
                :normalizer="normalizerContactsList"
                @blur="$refs.form.validateField('storeEmployeeId')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="storeTel">
              <el-input v-model="form.storeTel" maxlength="11" />
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 联系信息第二层 -->
        <el-row>
          <el-col :span="12">
            <el-form-item label="地址" prop="regionId">
              <el-cascader
                class="address"
                :options="provinceList"
                :props="defaultProp"
                v-model="form.regionId"
                maxlength="80"
                placeholder="请选择地址"
                filterable
                remote
                reserve-keyword
                clearable
              ></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 联系信息第三层 -->
        <el-row>
          <el-col :span="24">
            <el-form-item label="详细地址" prop="classNo">
              <el-input v-model="form.storeAddress" maxlength="200" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getAllRegion } from "@/api/goods/region"; //行政树
import { treeselect as getDepTree } from "@/api/system/dept";
import { allEmployeeList } from "@/api/system/employee";
import { addStore } from "@/api/goods/store";
export default {
  name: "StoreAdd",
  components: { Treeselect },
  dicts: [
    "App_is_allow_purchase",
    "App_is_allow_sale",
    "App_is_allow_minus",
    "cost_mode",
    "store_status",
    "store_type"
  ],
  props: {
    //打开新增仓库开关
    OpenStoreAdd: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      contactsList: [], //仓库负责人姓名
      provinceList: [], //全部行政地区
      // 部门树选项
      depOptions: [],
      defaultProp: {
        value: "id",
        label: "label",
        emitPath: false
      }, //地址
      // 表单参数
      form: {
        deptId: null,
        storeNo: null,
        storeName: null,
        storeType: null,
        storeUserId: null,
        storeTel: null,
        storeAddress: null,
        status: 1,
        isAllowPurchase: 1,
        isAllowSale: 1,
        isAllowMinus: 1,
        costMode: 0
      },
      // 表单校验
      rules: {
        storeTel: [
          {
            required: true,
            message: "联系电话不能为空",
            trigger: ["blur", "change"]
          }, //联系电话
          { pattern: /^1[3-9]\d{9}$/, message: "手机号格式不正确" }
        ],
        regionId: [
          {
            required: true,
            message: "仓库地址不能为空",
            trigger: ["blur", "change"]
          } //仓库地址
        ],
        storeEmployeeId: [
          {
            required: true,
            message: "仓库负责人不能为空",
            trigger: ["blur", "change"]
          } //负责人
        ],
        //所属机构
        deptId: [
          {
            required: true,
            message: "所属机构不能为空",
            trigger: ["blur", "change"]
          } //负责人
        ],
        storeType: [
          {
            required: true,
            message: "仓库类型不能为空",
            trigger: ["blur", "change"]
          }
        ],
        storeName: [
          {
            required: true,
            message: "仓库名称不能为空",
            trigger: ["blur", "change"]
          },
          { pattern: /^.{1,40}$/, message: "请输入1 ~ 40个字符" }
        ],
        storeNo: [
          {
            required: true,
            message: "仓库编码不能为空",
            trigger: ["blur", "change"]
          },
          { pattern: /^[0-9]{1,20}$/, message: "请输入1 ~ 20个数值类型的字符" }
        ]
      }
    };
  },
  watch:{
    //侦听对话框是否开启
    OpenStoreAdd: {
      handler (val) {
        this.getDepTree();
      },
      immediate: true
    }
  },
  methods: {
    //关闭对话框时的回调
    handleClose() {
      // 初始化表单
      (this.form = {
        deptId: null,
        storeNo: null,
        storeName: null,
        storeType: null,
        storeUserId: null,
        storeTel: null,
        storeAddress: null,
        status: 1,
        isAllowPurchase: 1,
        isAllowSale: 1,
        isAllowMinus: 1,
        costMode: 0
      }),
        this.$emit("update:OpenStoreAdd", false);
    },
    //负责人下拉树结构数据转换
    normalizerContactsList(node) {
      return {
        id: node.employeeId,
        label: node.employeeName,
        children: node.children
      };
    },
    /** 查询部门下拉树结构 */
    getDepTree() {
      getDepTree().then(response => {
        this.depOptions = response.data;
      });
      //获取全部行政地区事件
      getAllRegion().then(response => {
        this.provinceList = response.data;
      });
      allEmployeeList().then(response => {
        this.contactsList = response.data;
      });
    },
    //下拉树结构数据转换
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children
      };
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          addStore(this.form).then(response => {
            this.$modal.msgSuccess("新增仓库成功");
            this.$emit('getStore',response.data)
            this.handleClose()
          });
        }
      });
    }
  }
};
</script>

<style>
</style>